var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-dark d-flex flex-column justify-content-between rounded pt-4 pb-2 px-4 h-100 position-relative",attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_c('h3',{staticClass:"text-white-50 text-truncate"},[_vm._v(" Top Ranking "+_vm._s(_vm.type === 1 ? "Personas" : "Departamentos")+" ")]),(_vm.type === 1 ? _vm.topRankingPersona.length : _vm.topRanking.length)?_c('div',{staticClass:"row d-flex align-items-end px-2 mt-4 fade-in-out",staticStyle:{"height":"220px"}},_vm._l((_vm.type === 1 ? _vm.topRankingPersona : _vm.topRanking),function(ranking,index){return _c('div',{key:index,class:`col position-relative d-flex flex-column justify-content-end  align-items-center ranking-item ${
        _vm.type === 1 ? 'rankP' + ranking.top : 'rank' + ranking.top
      }`,style:(`
      
        border-radius: ${_vm.setBorderRadius(ranking.top)};
        background-color: ${ranking?.bgColor};
        padding-bottom: ${ranking.top === 1 ? '40px' : '10px'} ;
        `)},[_c('div',{staticClass:"position-absolute circle-ranking bg-gray-700",style:(`
          top: 0;
          left: 50%;
          display: grid;
          place-items: center;
          ${
            ranking.top === 1
              ? `box-shadow: 0px -1px 33px -8px ${ranking?.ptsColor}`
              : ``
          };
          border: 3px solid ${ranking?.ptsColor};
          transform: translate(-50%, -50%);
        `)},[_c('span',{style:(`
            font-size: 3rem;
            font-weight: 900;
            display: inline-block;
            background: linear-gradient( #fff, ${ranking?.ptsColor});
            -webkit-background-clip: text;
            color: transparent;
            `)},[_vm._v(" "+_vm._s(ranking.top)+" ")]),(ranking.top === 1)?_c('i',{staticClass:"fas fa-crown position-absolute fa-fade",style:(`bottom: 0; left: 50%; transform: translate(-50%, 50%);color: ${ranking?.ptsColor};font-size:1.5rem;`)}):_vm._e()]),_c('vs-avatar-group',{staticClass:"ranking-text",attrs:{"max":"3"}},_vm._l((_vm.type === 1
            ? ranking?.personas
            : ranking.departs),function(item,index){return _c('vs-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],key:index,style:(index == 1 &&
            (_vm.type === 1
              ? ranking?.personas.length
              : ranking.departs.length) >= 3 &&
            'margin-top: -20px'),attrs:{"title":item.name,"primary":"","circle":"","size":_vm.type === 1
              ? ranking?.personas.length == 1
                ? '40'
                : '30'
              : ranking.departs.length == 1
              ? '40'
              : '30'},scopedSlots:_vm._u([{key:"text",fn:function(){return [_vm._v(" "+_vm._s(item.name || "")+" ")]},proxy:true}],null,true)})}),1),_c('b',{staticClass:"ranking-text mt-1",style:(`
        color:  ${ranking?.ptsColor};
        font-size: 2rem;
      `)},[_vm._v(_vm._s(_vm.type === 1 ? ranking?.personas[0].pts : ranking.departs[0].pts)+" ")]),_c('b',{staticClass:"ranking-text",style:(`
        color:  ${ranking?.ptsColor};
      `)},[_vm._v(_vm._s(ranking.top == 1 ? "Oro" : ranking.top == 2 ? "Plata" : "Bronce"))])],1)}),0):_vm._e(),(
      _vm.type === 1
        ? _vm.topRankingPersona.length || _vm.rankingDataPersona.length
        : _vm.topRanking.length || _vm.rankingData.length
    )?_c('card',{staticClass:"mb-0 fade-in-out mt-3 mt-lg-0",staticStyle:{"height":"250px","max-height":"250px","overflow-y":"scroll","background":"url('img/insignia.png') center center no-repeat","background-size":"contain","background-color":"var(--gray-100)"},attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_vm._l((_vm.type === 1 ? _vm.rankingDataPersona : _vm.rankingData),function(rank,index){return _c('div',{key:index,staticClass:"d-flex align-items-center",class:rank.evaluadoId === _vm.usuario.id && 'bg-primary rounded ml-1',staticStyle:{"border-bottom":"#1d2b49"}},[_c('b',{staticClass:"p-3"},[_vm._v(_vm._s(3 + (index + 1)))]),_c('div',{staticClass:"d-flex align-items-center w-100 justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('i',{staticClass:"fas fa-medal p-2",staticStyle:{"font-size":"1rem"}}),_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(_vm._s(_vm.type === 1 ? rank.evaluado : rank.departamento))])])]),_c('b',{staticClass:"p-3"},[_vm._v(" "+_vm._s(rank.promedioValoracion)+" ")])])])}),(_vm.type === 1 ? !_vm.rankingDataPersona.length : !_vm.rankingData.length)?_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100 font-italic"},[_c('p',{staticClass:"text-white-50 h4"},[_vm._v(" Lista para los que quedan fuera del podio. ")])]):_vm._e()],2):_vm._e(),(
      _vm.type === 1
        ? _vm.topRankingPersona.length == 0 && _vm.rankingDataPersona.length == 0
        : _vm.topRanking.length == 0 && _vm.rankingData.length == 0
    )?_c('div',{staticClass:"row align-items-center h-100 fade-in-out empty-grafic"},[_vm._m(0),_c('div',{staticClass:"col-12 text-center",attrs:{"data-aos":"fade-down","data-aos-duration":"1000"}},[_c('h3',[_vm._v(" Resultados Evaluaciones "+_vm._s(_vm.type === 1 ? "Personas" : "Departamentos")+" ")]),_c('p',{staticClass:"text-white-50 h4"},[_vm._v(" "+_vm._s(_vm.type === 1 ? " Las personas de tú area con mejores resultados estarán en el podio.🏆" : " Los departamentos con mejores resultados estarán en el podio.🏆")+" ")])])]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 d-flex justify-content-center icon-ranking pb-5"},[_c('div',{staticClass:"custom-bg-icon position-absolute",staticStyle:{"border-radius":"30% 70% 70% 30% / 30% 30% 70% 70%","width":"200px","height":"200px"}}),_c('img',{staticClass:"position-absolute",staticStyle:{"width":"160px"},attrs:{"loading":"lazy","src":"img/ranking.png","alt":"ranking"}})])
}]

export { render, staticRenderFns }