<template>
  <card class="w-100 p-2">
    <div v-if="listaReportes.length > 0" class="row">
      <div
        class="col-12 d-flex flex-column flex-lg-row align-items-center justify-content-start bg-dark rounded py-2 mb-3"
      >
        <div class="d-flex w-100" style="gap: 0.5rem">
          <i
            class="fas fa-key p-2 rounded-circle border"
            style="font-size: 1.5rem"
          ></i>
          <div class="d-flex align-items-center flex-wrap">
            <small class="text-capitalize" style="font-size: 1rem"
              >contraseña reporte</small
            >
          </div>
        </div>
        <input
          readonly
          ref="passwordInput"
          :type="`${showPassword ? 'text' : 'password'}`"
          :style="showPassword ? 'fontSize:1.5rem' : ''"
          class="custom-input-password"
          :value="usuario?.contrasenaReportes"
        />
        <div class="d-flex">
          <vs-button
            class="w-100"
            size="large"
            
            :active="active == 3"
            @click="
              copyToClipboard(
                'top-left',
                'primary',
                `<i class='fas fa-copy'></i>`
              )
            "
          >
            <i
              :class="`fas fa-${
                copyPasswordText ? 'circle-check' : 'copy'
              } mr-1`"
            ></i>
            {{ copyPasswordText ? "Copiada!" : "Copiar" }}
          </vs-button>
          <vs-button
            class="w-100"
            size="large"
            :flat="showPassword"
            :gradient="!showPassword"
            :danger="showPassword"
            :success="!showPassword"
            @click="togglePasswordVisibility"
          >
            <i :class="`fas fa-${showPassword ? 'eye-slash' : 'eye'} mr-1`"></i>
            {{ showPassword ? "Ocultar" : "Ver" }}
          </vs-button>
        </div>
      </div>
      <!-- reporte -->
      <div
        class="col-12 col-lg bg-dark p-2 rounded reporte"
        style="height: 500px"
      >
        <div
          class="vista-previa d-flex justify-content-center align-items-center rounded"
        >
          <h5>Cargando reporte...</h5>
          <modalIframe
            :url="selectedReport?.url ?? ''"
            :likeElement="true"
          ></modalIframe>
        </div>
      </div>
      <!-- content list reports -->
      <div class="col-12 col-lg-4 content-list-reports">
        <div
          class="row list-reports reportListContainer"
          ref="reportListContainer"
        >
          <div
            v-for="(reporte, index) in listaReportes"
            :key="index"
            class="col-12 d-flex align-items-center rounded card-report mt-2"
            @click="clickHandle(reporte, index)"
          >
            <div
              class="image-container w-100 rounded"
              :style="{
                transition: 'box-shadow 0.3s ease',
                boxShadow:
                  index === selectedReport?.index
                    ? '0 0 0 3px #186bdd'
                    : 'none',
                cursor: 'default',
              }"
            >
              <picture>
                <div class="d-block text-center">
                  <div class="d-flex justify-content-center mt-4">
                    <div
                      style="max-height: 200px; height: 200px"
                      class="d-flex justify-content-center align-items-center"
                    >
                      <vs-button
                        relief
                        danger
                        size="small"
                        style="
                          z-index: 10;
                          top: 0;
                          left: 10px;
                          border-radius: 0 0 5px 5px;
                        "
                        class="position-absolute m-0"
                        >Nivel {{ reporte.nivel }}</vs-button
                      >
                      <h5>Vista previa...</h5>
                      <modalIframe
                        class="iframe"
                        :url="reporte?.url ?? ''"
                        :likeElement="true"
                      ></modalIframe>
                    </div>
                  </div>
                </div>
              </picture>
              <div
                class="overlay rounded"
                :style="{
                  opacity: index === selectedReport?.index ? '1' : '0',
                }"
              >
                <article
                  class="d-flex flex-column align-items-center"
                  style="gap: 0.5rem"
                >
                  <i
                    class="fas fa-chart-simple text-white"
                    style="font-size: 2rem"
                  ></i>
                  <p class="custom-descripcion text-white-50">
                    {{ reporte.descripcion }}
                  </p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="text-light bg-dark rounded"
      v-else
      style="height: 600px; display: grid; place-items: center; font-size: 2rem"
    >
      <helexium-loader class="loader" />
    </div>
  </card>
</template>

<script>
import modalIframe from "@/components/helexium/modalIframe.vue";
import HelexiumLoader from "@/components/helexium/HelexiumLoader.vue";
export default {
  components: {
    modalIframe,
    HelexiumLoader,
  },
  data() {
    return {
      active: null,
      usuario: {},
      selectedReport: null,
      copyPasswordText: null,
      showPassword: false,
      listaReportes: [
        {
          url: null,
          descripcion: null,
          nivel: null,
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    // console.log("userActivo", this.usuario);
    this.listaReportes = [];
    await this.GetByCompanyIdAndReportingLevel(
      this.usuario.empresa.id,
      this.usuario.nivelReporte
    );
    this.$isLoading(false);
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async copyToClipboard(position = null, color, icon) {
      try {
        const inputPassword = this.$refs.passwordInput.value;
        if (inputPassword) {
          await navigator.clipboard.writeText(inputPassword);

          this.copyPasswordText = inputPassword;

          setTimeout(() => {
            this.copyPasswordText = null;
          }, 6000);

          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon,
            color,
            position,
            title: "Contraseña copiada!!",
          });
        }
      } catch (err) {
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: '<i class="fas fa-xmark"></i>',
          color: "danger",
          position,
          title: "No se pudo copiar al portapapeles!!",
          text: err,
        });
        // console.error("No se pudo copiar al portapapeles!!: ", err);
      }
    },
    clickHandle(reporte, index) {
      this.selectedReport = reporte;
      this.selectedReport.index = index;
      // Obtener el contenedor de la lista de clases
      const container = this.$refs.reportListContainer;

      // Obtener el elemento de la lista correspondiente al reporte seleccionado
      const element = container.children[index];

      // Calcular la posición del centro del contenedor
      const containerCenter = container.clientHeight / 2;

      // Calcular la posición del elemento seleccionado
      const elementCenter = element.offsetTop + element.clientHeight / 2;

      // Ajustar el scroll para que la clase seleccionada quede centrada verticalmente
      container.scrollTop = elementCenter - containerCenter;
    },
    async GetByCompanyIdAndReportingLevel(companyId, reportLevel) {
      try {
        const nivelesReportes = JSON.parse(reportLevel);

        if (Array.isArray(nivelesReportes)) {
          nivelesReportes.forEach(async (nivel) => {
            let res = await this.$store.dispatch("hl_get", {
              path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivel}`,
            });
            // console.log("reportes----------------->", res, nivelesReportes);
            if (res && res.url) {
              this.listaReportes.push({
                url: res.url,
                descripcion: res.description,
                nivel: res.reportingLevel,
              });
              this.listaReportes.sort((a, b) => a.nivel - b.nivel);
              this.selectedReport = { ...this.listaReportes[0], index: 0 };
            }
          });
        } else {
          let res = await this.$store.dispatch("hl_get", {
            path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivelesReportes}`,
          });
          // console.log("reportes----------------->", res, nivelesReportes);
          if (res.url) {
            this.listaReportes = [
              {
                url: res.url,
                descripcion: res.description,
                nivel: res.reportingLevel,
              },
            ];
            this.selectedReport = { ...this.listaReportes[0], index: 0 };
          }
        }
      } catch (error) {
        // console.log("err", error);
      }
    },
  },
};
</script>

<style scoped>
.vista-previa {
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 300px; */
}

.content-list-reports {
  position: relative;
  max-height: 500px;
  overflow: hidden;
}
.list-reports {
  max-height: 500px;
  overflow-y: scroll;
  gap: 0.5rem;
  padding-bottom: 200px;
  scroll-behavior: smooth;
}

.list-reports::-webkit-scrollbar-thumb {
  background: rgba(250, 250, 250, 0.1);
}

.content-list-reports::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #f4f4fe);
  pointer-events: none;
}
.dark .content-list-reports::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(16, 36, 68));
  pointer-events: none;
}

@keyframes show {
  from {
    opacity: 0;
    scale: 25%;
  }
  to {
    opacity: 1;
    scale: 100%;
  }
}

.card-report {
  view-timeline-name: --reporte;
  view-timeline-axis: block;

  animation-timeline: --reporte;
  animation-name: show;
  animation-range: entry 25% cover 30%;
  animation-fill-mode: both;
}

/* imagen container */

.image-container {
  position: relative;
  display: inline-block;
  overflow: hidden;
}

.image-container .iframe {
  transition: transform 0.3s ease;
}

.image-container:hover .iframe {
  transform: scale(1.2);
}

.overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  height: 100%;
  /* glassEffect */
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 1);

  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay p {
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.custom-descripcion {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* custom input password */
.custom-input-password {
  background: transparent;
  border: none;
  width: 100%;
  height: 50px;
  color: var(--text-progress);
  font-size: 3rem;
  cursor: default;
  transition: all 0.3s ease;
  animation: fadeIn 3s infinite;
}

@keyframes fadeIn {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .reporte {
    height: 300px !important;
    margin-bottom: 1rem;
  }
}
</style>
