<script>
// const Swal = require("sweetalert2");

export default {
  components: {},
  data() {
    return {
      usuario: {},
      file: null,
      filename: null,
      estadisticasProfile: {
        empresaId: null,
        personaId: null,
        departamentoId: null,
        cargoId: null,
        nombre_empresa: null,
        departamento: null,
        nombreEvaluado: null,
        cargo: null,
        pulsoEmpresa: 0,
        pulsoDepartamento: 0,
        pulsoPersona: 0,
      },
      personaUbicacion: {
        cargo: {
          nombre: "Cargo",
        },
      },
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.getPersonaUbicacion(this.usuario.id);
      const { id, empresa } = this.usuario;
      await this.getEstadisticasProfile(id, empresa.id);
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    async getEstadisticasProfile(personaId, empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `EstadisticasDashboardProfile/${personaId}/${empresaId}`,
        });
        // console.log("estadisticas profile------------>", res);
        if (res.success) {
          this.estadisticasProfile = res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPersonaUbicacion(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        // console.log("persona ubicacion...", res);
        if (res.cargo) {
          this.personaUbicacion = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPersonaByIDAfterUploadImagenPerfil(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPersona/" + personaId,
        });
        // console.log("PersonaAfterUploadImagenPerfil... ", res);

        localStorage.setItem("setPersonaAct", JSON.stringify(res));

        this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      } catch (error) {
        // console.log("err", error);
      }
    },
    uploadImage() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      // Realiza las acciones necesarias con el archivo de imagen
      // console.log("Archivo seleccionado:", file);

      this.uploadImagenPerfilUrl(file);
    },
    async renameFile(nameFile) {
      this.realName = nameFile;
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    async uploadImagenPerfilUrl(file) {
      try {
        this.$isLoading(true);
        this.file = file; //event.target.files[0];
        this.filename = await this.renameFile(this.file.name); // Math.floor(Math.random() * 9999999999999 + 1);
        // console.log("aaaaaaaaaaaaaaa", this.file, this.filename);
        if (this.filename != null) {
          let url = ".|site1|users|photoPerfil|" + this.usuario.id;

          let uploadImg = await this.$store.dispatch("onFileCustom", {
            filename: this.filename,
            file: this.file,
            url: url,
          });

          if (uploadImg == true) {
            // console.log("archivo subido...", uploadImg);

            let data = {
              personaId: this.usuario.id,
              url:
                "https://www.helexiumfiles.com/users/photoPerfil/" +
                this.usuario.id +
                "/" +
                this.filename,
            };

            // console.log(data);

            try {
              await this.$store.dispatch("hl_post", {
                path: "Persona/UploadImagenPerfilUrl",
                data,
              });

              this.$vs.notification({
                // duration: "none",
                progress: "auto",
                color: "primary",
                icon: "<i class='fas fa-circle-check'></i>",
                position: "top-center",
                title: "Foto de perfil actualizada!",
              });

              this.getPersonaByIDAfterUploadImagenPerfil(data.personaId);
              // Swal.fire({
              //   title: "Listo!",
              //   text: "Se ha guardado la foto.",
              //   icon: "success",
              //   showCancelButton: false,
              //   confirmButtonColor: "#3085d6",
              //   cancelButtonColor: "#d33",
              //   confirmButtonText: "OK",
              //   cancelButtonText: "Cancelar",
              // }).then(async (result) => {
              //   if (result.isConfirmed) {
              //     this.getPersonaByIDAfterUploadImagenPerfil(data.personaId);
              //   }
              // });
            } catch (error) {
              // console.error(error);
            }
          }
        }
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        // Swal.fire(
        //   "Error!",
        //   "Ha ocurrido un error al guardar la foto.",
        //   "error"
        // );
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          color: "danger",
          icon: "<i class='fas fa-xmark'></i>",
          position: "top-center",
          title: "Ha ocurrido un error al subir la foto.",
        });
        // console.log("errror upload", error);
      }
    },
  },
};
</script>

<template>
  <div class="wrapper">
    <label for="file-upload" class="between">
      <img
        :src="usuario.imagenPerfilUrl"
        v-show="usuario.imagenPerfilUrl"
        data-aos="zoom-in"
        class="image"
        alt="imagen_perfil"
        @click="uploadImage"
      />
    </label>
    <input
      id="file-upload"
      type="file"
      accept="image/*"
      @change="handleFileUpload"
      hidden
    />
  </div>
</template>

<style scoped>
.image,
.between,
.wrapper {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
