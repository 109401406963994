<template>
  <div
    v-if="cantidadResportes > 0"
    class="bg-dark p-4 rounded position-relative h-50 d-flex flex-column"
    data-aos="fade-left"
    data-aos-duration="1000"
    v-b-modal.modal_report
  >
    <!-- cantidad de reportes -->
    <div
      v-b-tooltip.hover
      title="Cantidad de Reportes"
      class="position-absolute"
      style="
        display: grid;
        place-items: center;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
      "
    >
      <vs-button class="font-weight-bold" circle danger relief>
        {{ cantidadResportes }}
      </vs-button>
    </div>

    <h3 class="text-white-50">Reportes</h3>

    <div class="row w-100">
      <div
        class="col-12 col-xl d-flex flex-column justify-content-center align-items-center"
      >
        <div
          class="rounded-circle text-white d-flex justify-content-center align-items-center icon-reports"
          style="
            width: 80px;
            height: 80px;
            background: radial-gradient(circle, #ff94a7, #d70027);
            box-shadow: 0px -1px 33px -8px #d70027;
            -webkit-box-shadow: 0px -1px 33px -8px #d70027;
            -moz-box-shadow: 0px -1px 33px -8px #d70027;
          "
        >
          <i class="fas fa-chart-simple" style="font-size: 2rem"></i>
        </div>
        <p
          style="line-height: 1.5; font-size: 0.8rem"
          class="text-white-50 mt-2"
        >
          Haz click aquí para visualizar tus reportes.
        </p>
      </div>
    </div>
    <!-- modal powerBIreport -->
    <b-modal size="xl" id="modal_report" title="Reportes" hide-footer centered>
      <slides_report />
    </b-modal>
  </div>
</template>
<script>
import slides_report from "./slides_report.vue";

export default {
  components: {
    slides_report,
  },
  data() {
    return {
      usuario: {},
      listaResportes: [],
      cantidadResportes: null,
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.GetByCompanyIdAndReportingLevel(
        this.usuario.empresa.id,
        this.usuario.nivelReporte
      );
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    async GetByCompanyIdAndReportingLevel(companyId, reportLevel) {
      try {
        const nivelesReportes = JSON.parse(reportLevel);

        if (Array.isArray(nivelesReportes)) {
          nivelesReportes.forEach(async (nivel) => {
            let res = await this.$store.dispatch("hl_get", {
              path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivel}`,
            });
            // console.log("reportes----------------->", res, nivelesReportes);
            if (res && res.url) {
              this.listaResportes.push({
                url: res.url,
                descripcion: res.description,
                nivel: res.reportingLevel,
              });
              this.cantidadResportes = this.listaResportes.length;
            }
          });
        } else {
          let res = await this.$store.dispatch("hl_get", {
            path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivelesReportes}`,
          });
          // console.log("reportes----------------->", res, nivelesReportes);
          if (res.url) {
            this.listaResportes.push({
              url: res.url,
              descripcion: res.description,
              nivel: res.reportingLevel,
            });
            this.cantidadResportes = this.listaResportes.length;
          }
        }
      } catch (error) {
        // console.log("err", error);
      }
    },
  },
};
</script>
<style scoped>
.icon-reports {
  animation: leftAndRight 2s ease-in-out infinite;
}

@keyframes leftAndRight {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
