<template>
  <div
    class="bg-dark d-flex flex-column justify-content-between rounded pt-4 pb-2 px-4 h-100 position-relative"
    data-aos="fade-up"
    data-aos-duration="1000"
  >
    <!-- icon -->
    <!-- <div
      class="position-absolute"
      style="top: 0 !important; right: 0 !important"
    >
      <vs-button flat icon success to="/dashboard">
        <i class="fas fa-ranking-star"></i>
      </vs-button>
    </div> -->
    <!-- title -->
    <h3 class="text-white-50 text-truncate">
      Top Ranking {{ type === 1 ? "Personas" : "Departamentos" }}
    </h3>

    <!-- top grafic -->
    <div
      v-if="type === 1 ? topRankingPersona.length : topRanking.length"
      class="row d-flex align-items-end px-2 mt-4 fade-in-out"
      style="height: 220px"
    >
      <div
        v-for="(ranking, index) of type === 1 ? topRankingPersona : topRanking"
        :key="index"
        :class="`col position-relative d-flex flex-column justify-content-end  align-items-center ranking-item ${
          type === 1 ? 'rankP' + ranking.top : 'rank' + ranking.top
        }`"
        :style="`
        
          border-radius: ${setBorderRadius(ranking.top)};
          background-color: ${ranking?.bgColor};
          padding-bottom: ${ranking.top === 1 ? '40px' : '10px'} ;
          `"
      >
        <!-- avatar -->
        <!-- <vs-avatar
        
          class="position-absolute "
          :style="`
            top: 0;
            left: 50%;
            display: grid;
            place-items: center;
            ${
              ranking.top === 1
                ? `box-shadow: 0px -1px 33px -8px ${ranking?.ptsColor}`
                : ``
            };
            border: 3px solid ${ranking?.ptsColor};
            transform: translate(-50%, -50%);
          `"
          
          size="80"
          circle
        > -->
        <div
          class="position-absolute circle-ranking bg-gray-700"
          :style="`
            top: 0;
            left: 50%;
            display: grid;
            place-items: center;
            ${
              ranking.top === 1
                ? `box-shadow: 0px -1px 33px -8px ${ranking?.ptsColor}`
                : ``
            };
            border: 3px solid ${ranking?.ptsColor};
            transform: translate(-50%, -50%);
          `"
        >
          <!-- top level -->
          <span
            :style="`
              font-size: 3rem;
              font-weight: 900;
              display: inline-block;
              background: linear-gradient( #fff, ${ranking?.ptsColor});
              -webkit-background-clip: text;
              color: transparent;
              `"
          >
            {{ ranking.top }}
          </span>
          <!-- king -->
          <i
            v-if="ranking.top === 1"
            class="fas fa-crown position-absolute fa-fade"
            :style="`bottom: 0; left: 50%; transform: translate(-50%, 50%);color: ${ranking?.ptsColor};font-size:1.5rem;`"
          ></i>
        </div>

        <vs-avatar-group max="3" class="ranking-text">
          <vs-avatar
            v-b-tooltip.hover.v-primary
            :title="item.name"
            v-for="(item, index) in type === 1
              ? ranking?.personas
              : ranking.departs"
            :key="index"
            primary
            circle
            :size="
              type === 1
                ? ranking?.personas.length == 1
                  ? '40'
                  : '30'
                : ranking.departs.length == 1
                ? '40'
                : '30'
            "
            :style="
              index == 1 &&
              (type === 1
                ? ranking?.personas.length
                : ranking.departs.length) >= 3 &&
              'margin-top: -20px'
            "
          >
            <template #text> {{ item.name || "" }} </template>
          </vs-avatar>
        </vs-avatar-group>

        <!-- pts -->
        <b
          class="ranking-text mt-1"
          :style="`
          color:  ${ranking?.ptsColor};
          font-size: 2rem;
        `"
          >{{ type === 1 ? ranking?.personas[0].pts : ranking.departs[0].pts }}
        </b>
        <b
          class="ranking-text"
          :style="`
          color:  ${ranking?.ptsColor};
        `"
          >{{
            ranking.top == 1 ? "Oro" : ranking.top == 2 ? "Plata" : "Bronce"
          }}</b
        >
      </div>
    </div>

    <!-- list ranking -->
    <card
      v-if="
        type === 1
          ? topRankingPersona.length || rankingDataPersona.length
          : topRanking.length || rankingData.length
      "
      class="mb-0 fade-in-out mt-3 mt-lg-0"
      style="
        height: 250px;
        max-height: 250px;
        overflow-y: scroll;
        background: url('img/insignia.png') center center no-repeat;
        background-size: contain;
        background-color: var(--gray-100);
      "
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div
        v-for="(rank, index) in type === 1 ? rankingDataPersona : rankingData"
        :key="index"
        :class="rank.evaluadoId === usuario.id && 'bg-primary rounded ml-1'"
        class="d-flex align-items-center"
        style="border-bottom: #1d2b49"
      >
        <b class="p-3">{{ 3 + (index + 1) }}</b>
        <div class="d-flex align-items-center w-100 justify-content-between">
          <div class="d-flex align-items-center">
            <i class="fas fa-medal p-2" style="font-size: 1rem"></i>
            <div class="d-flex flex-column">
              <span>{{ type === 1 ? rank.evaluado : rank.departamento }}</span>
              <!-- <small class="text-white-50">Tester</small> -->
            </div>
          </div>
          <b class="p-3"> {{ rank.promedioValoracion }} </b>
        </div>
      </div>
      <!-- rankingData empty -->
      <div
        v-if="type === 1 ? !rankingDataPersona.length : !rankingData.length"
        class="d-flex justify-content-center align-items-center h-100 font-italic"
      >
        <p class="text-white-50 h4">
          Lista para los que quedan fuera del podio.
        </p>
      </div>
    </card>

    <!-- empty grafic -->
    <div
      v-if="
        type === 1
          ? topRankingPersona.length == 0 && rankingDataPersona.length == 0
          : topRanking.length == 0 && rankingData.length == 0
      "
      class="row align-items-center h-100 fade-in-out empty-grafic"
    >
      <div class="col-12 d-flex justify-content-center icon-ranking pb-5">
        <div
          class="custom-bg-icon position-absolute"
          style="
            border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
            width: 200px;
            height: 200px;
          "
        ></div>

        <img
          loading="lazy"
          class="position-absolute"
          style="width: 160px"
          src="img/ranking.png"
          alt="ranking"
        />
      </div>
      <div
        class="col-12 text-center"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <h3>
          Resultados Evaluaciones
          {{ type === 1 ? "Personas" : "Departamentos" }}
        </h3>
        <p class="text-white-50 h4">
          {{
            type === 1
              ? " Las personas de tú area con mejores resultados estarán en el podio.🏆"
              : " Los departamentos con mejores resultados estarán en el podio.🏆"
          }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: Number,
  },
  components: {},
  data() {
    return {
      // color:'--body-bg',
      usuario: {},
      rankingDataPersona: [
        {
          empresaId: null,
          empresa: null,
          evaluadoId: null,
          evaluado: null,
          areaId: null,
          area: null,
          promedioValoracion: 0,
        },
      ],
      rankingData: [
        {
          EmpresaId: null,
          nombre_empresa: null,
          DepartamentoId: null,
          departamento: null,
          promedioValoracion: 0,
        },
      ],
      colors: [
        {
          bgColor: "var(--white)",
          ptsColor: "#ffc40f",
        },
        {
          bgColor: "var(--gray-300)",
          ptsColor: "var(--plata)",
        },
        {
          bgColor: "var(--gray-400)",
          ptsColor: "#e49844",
        },
      ],
      topRanking: [
        {
          top: 2,
          departs: [
            {
              name: "Finanzas",
              pts: 89,
            },
          ],
          bgColor: "var(--white)",
          ptsColor: "#a6b1ca",
        },
        {
          top: 1,
          departs: [
            {
              name: "Logistica",
              pts: 98,
            },
          ],
          bgColor: "var(--gray-300)",
          ptsColor: "#ffc40f",
        },
        {
          top: 3,
          departs: [
            {
              name: "Gerencia",
              pts: 79,
            },
          ],
          bgColor: "var(--gray-400)",
          ptsColor: "#e49844",
        },
      ],
      topRankingPersona: [
        {
          top: 2,
          personas: [
            {
              name: "Sugey",
              pts: 89,
            },
          ],
          bgColor: "#1d2b49",
          ptsColor: "#a6b1ca",
        },
        {
          top: 1,
          personas: [
            {
              name: "Sheyla",
              pts: 98,
            },
          ],
          bgColor: "#233458",
          ptsColor: "#ffc40f",
        },
        {
          top: 3,
          personas: [
            {
              name: "Joe",
              pts: 79,
            },
          ],
          bgColor: "#1d2b49",
          ptsColor: "#e49844",
        },
      ],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));

      this.rankingData = [];
      this.rankingDataPersona = [];
      this.topRanking = [];
      this.topRankingPersona = [];

      await this.getListEvaluacionesByEmpresa(this.usuario.empresa.id);

      await this.getListEvaluacionesRankingAreaByPersona(this.usuario.id);

      setTimeout(() => {
        for (const ranking of this.type === 1
          ? this.topRankingPersona
          : this.topRanking) {
          let rank = document.querySelector(
            `${
              this.type === 1 ? ".rankP" + ranking.top : ".rank" + ranking.top
            }`
          );
          let texts = document.querySelectorAll(".ranking-text");
          if (ranking.top === 1) {
            rank?.classList.add("top-1");
          } else {
            rank?.classList.add("top-2-3");
          }

          for (const text of texts) {
            text?.classList.add("show-text");
          }
        }
      }, 1000);

      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.log("error", error);
    }
  },
  methods: {
    async getListEvaluacionesRankingAreaByPersona(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "EstadisticasEvaluacionesEmpresaV2/PromedioEvaluacionesRankingAreaPersona/" +
            personaId,
        });
        console.log("list PromedioEvaluacionesRankingAreaPersona... ", res);
        if (res.success && res.data.length) {
          // Agrupar por promedioValoracion y ordenar cada grupo
          const groupedRanking = res.data.reduce((groups, item) => {
            const key = item.promedioValoracion.toString();
            groups[key] = (groups[key] || []).concat(item);
            // Ordenar cada grupo en orden descendente por promedioValoracion
            groups[key].sort(
              (a, b) => b.promedioValoracion - a.promedioValoracion
            );
            return groups;
          }, {});

          // Obtener las keys (valores únicos de promedioValoracion) y ordenarlas en orden descendente
          const uniqueKeys = Object.keys(groupedRanking).sort(
            (a, b) => parseFloat(b) - parseFloat(a)
          );

          // console.log(uniqueKeys);

          // Crear topRanking y remainingRanking según los grupos ordenados
          this.topRankingPersona = uniqueKeys.slice(0, 3).map((key, index) => {
            return {
              top: index + 1,
              personas: groupedRanking[key].map((item) => ({
                name: item.evaluado,
                pts: item.promedioValoracion,
              })),
              bgColor: this.colors[index].bgColor,
              ptsColor: this.colors[index].ptsColor,
            };
          });

          // Intercambiar las posiciones de 1 y 2 usando desestructuración
          [this.topRankingPersona[0], this.topRankingPersona[1]] = [
            this.topRankingPersona[1],
            this.topRankingPersona[0],
          ];

          // console.log(this.topRankingPersona);

          this.rankingDataPersona = uniqueKeys
            .slice(3)
            .flatMap((key) => groupedRanking[key]);

          console.log(this.rankingDataPersona);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getListEvaluacionesByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "EstadisticasEvaluacionesEmpresaV2/PromedioEvaluacionesDepartamentos/" +
            empresaId,
        });
        console.log("list PromedioEvaluacionesDepartamentos... ", res);
        if (res.success && res.data.length) {
          // Agrupar por promedioValoracion y ordenar cada grupo
          const groupedRanking = res.data.reduce((groups, item) => {
            const key = item.promedioValoracion.toString();
            groups[key] = (groups[key] || []).concat(item);
            // Ordenar cada grupo en orden descendente por promedioValoracion
            groups[key].sort(
              (a, b) => b.promedioValoracion - a.promedioValoracion
            );
            return groups;
          }, {});

          // Obtener las keys (valores únicos de promedioValoracion) y ordenarlas en orden descendente
          const uniqueKeys = Object.keys(groupedRanking).sort(
            (a, b) => parseFloat(b) - parseFloat(a)
          );

          // console.log(uniqueKeys);

          // Crear topRanking y remainingRanking según los grupos ordenados
          this.topRanking = uniqueKeys.slice(0, 3).map((key, index) => {
            return {
              top: index + 1,
              departs: groupedRanking[key].map((item) => ({
                name: item.departamento,
                pts: item.promedioValoracion,
              })),
              bgColor: this.colors[index].bgColor,
              ptsColor: this.colors[index].ptsColor,
            };
          });

          // Intercambiar las posiciones de 1 y 2 usando desestructuración
          [this.topRanking[0], this.topRanking[1]] = [
            this.topRanking[1],
            this.topRanking[0],
          ];

          // console.log(this.topRanking);

          this.rankingData = uniqueKeys
            .slice(3)
            .flatMap((key) => groupedRanking[key]);

          console.log(this.rankingData);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    setBorderRadius(top) {
      const colors = [
        {
          id: 1,
          borderRadius: "20px 20px 0 0",
        },
        {
          id: 2,
          borderRadius: "20px 0 0 20px",
        },
        {
          id: 3,
          borderRadius: "0 20px 20px 0",
        },
      ];

      const selectedColor = colors.find((item) => item.id === top);

      return selectedColor ? selectedColor.borderRadius : "";
    },
  },
};
</script>
<style scoped>
.circle-ranking {
  width: 5rem;
  border-radius: 50%;
}

.ranking-text {
  transition: opacity 3s ease;
  opacity: 0;
}
.show-text {
  opacity: 1;
}
.ranking-item {
  transition: height 3s ease;
  height: 0;
}
.top-1 {
  height: 100% !important;
}
.top-2-3 {
  height: 80% !important;
}
.custom-bg-icon {
  background: radial-gradient(circle, #ff94a7, #d70027);
  box-shadow: 0px -1px 33px -8px #d70027;
  -webkit-box-shadow: 0px -1px 33px -8px #d70027;
  -moz-box-shadow: 0px -1px 33px -8px #d70027;
}
.icon-ranking {
  animation: leftAndRight 2s ease-in-out infinite;
}

.empty-grafic {
  min-height: 500px;
}

@keyframes leftAndRight {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-out {
  animation: fadeInOut 0.5s ease-in-out;
}
</style>
