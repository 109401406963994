<script>

export default {
  props: {
    inheritedStringAvatar: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      characters: '',
      colors: {}
    }
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    }
  },
  mounted() {
    if( this.inheritedStringAvatar ) {
      this.setAvatar(this.inheritedStringAvatar)
    } else {
      this.$store.getters.fetchGet({ path: `Persona/GetPersonaById/${this.userLoggedIn.id}` })
      .then( response => response.json() )
      .then( result => {
        if( result.stringAvatar ) {
          this.setAvatar(result.stringAvatar)
        } 
      })
    }

    
  },
  methods: {
    setAvatar( string ) {
      const myObject = JSON.parse(string)
      const characters = []
      const colors = {}
      Object.keys(myObject).forEach( key => {
        if( key.includes('-') ) {
          colors[key] = myObject[key]
        } else {
          characters.push( myObject[key] )
        }
      })
      console.log(colors)
      this.characters = characters.join(' ')
      this.colors = colors
    } 
  }
}

</script>
<template>
  <div :data-css-peeps="characters" :style="colors">

  </div>
</template>

<style scoped>
div {
  height: 300px;
  width: 300px;
}

</style>

