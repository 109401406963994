<template>
  <div
    class="bg-dark p-4 rounded position-relative h-100 d-flex flex-column justify-content-between"
    data-aos="fade-up"
    data-aos-duration="1200"
  >
    <!-- icon -->
    <div
      v-b-tooltip.hover
      :title="toTitle || 'Ir al Dashboard (ejemplo)'"
      class="position-absolute"
      style="top: 0 !important; right: 0 !important"
    >
      <vs-button flat icon success :to="to ?? '/dashboard'">
        <i :class="`fas fa-${icon ?? 'circle-up'}`"></i>
      </vs-button>
    </div>
    <!-- text info -->
    <div class="d-flex flex-column">
      <h3 class="text-white-50 text-truncate">
        {{ title ?? "Info progress" }}
      </h3>
      <span class="font-weight-bold h4">{{ info ?? "0 min" }}</span>
    </div>
    <div class="d-flex justify-content-center align-items-center h-100 p-1">
      <ProgressChart :total="total ?? 0" :confirmed="confirmed ?? 0" />
    </div>
  </div>
</template>
<script>
import ProgressChart from "@/components/charts/ProgressChart.vue";
export default {
  props: {
    title: String,
    info: String,
    icon: String,
    to: String,
    toTitle: String,
    total: Number,
    confirmed: Number,
  },
  components: { ProgressChart },
  data() {
    return {
      usuario: {},
      listaResportes: [],
      cantidadResportes: null,
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
      await this.GetByCompanyIdAndReportingLevel(
        this.usuario.empresa.id,
        this.usuario.nivelReporte
      );
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      // console.log("error", error);
    }
  },
  methods: {
    async GetByCompanyIdAndReportingLevel(companyId, reportLevel) {
      try {
        const nivelesReportes = JSON.parse(reportLevel);

        if (Array.isArray(nivelesReportes)) {
          nivelesReportes.forEach(async (nivel) => {
            let res = await this.$store.dispatch("hl_get", {
              path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivel}`,
            });
            // console.log("reportes----------------->", res, nivelesReportes);
            if (res && res.url) {
              this.listaResportes.push({
                url: res.url,
                descripcion: res.description,
                nivel: res.reportingLevel,
              });
              this.cantidadResportes = this.listaResportes.length;
            }
          });
        } else {
          let res = await this.$store.dispatch("hl_get", {
            path: `PowerBI/GetByCompanyIdAndReportingLevel/${companyId}/${nivelesReportes}`,
          });
          // console.log("reportes----------------->", res, nivelesReportes);
          if (res.url) {
            this.listaResportes.push({
              url: res.url,
              descripcion: res.description,
              nivel: res.reportingLevel,
            });
            this.cantidadResportes = this.listaResportes.length;
          }
        }
      } catch (error) {
        // console.log("err", error);
      }
    },
  },
};
</script>
<style scoped></style>
