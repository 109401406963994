var render = function render(){var _vm=this,_c=_vm._self._c;return _c('card',{staticClass:"w-100 p-2"},[(_vm.listaReportes.length > 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex flex-column flex-lg-row align-items-center justify-content-start bg-dark rounded py-2 mb-3"},[_c('div',{staticClass:"d-flex w-100",staticStyle:{"gap":"0.5rem"}},[_c('i',{staticClass:"fas fa-key p-2 rounded-circle border",staticStyle:{"font-size":"1.5rem"}}),_c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('small',{staticClass:"text-capitalize",staticStyle:{"font-size":"1rem"}},[_vm._v("contraseña reporte")])])]),_c('input',{ref:"passwordInput",staticClass:"custom-input-password",style:(_vm.showPassword ? 'fontSize:1.5rem' : ''),attrs:{"readonly":"","type":`${_vm.showPassword ? 'text' : 'password'}`},domProps:{"value":_vm.usuario?.contrasenaReportes}}),_c('div',{staticClass:"d-flex"},[_c('vs-button',{staticClass:"w-100",attrs:{"size":"large","active":_vm.active == 3},on:{"click":function($event){return _vm.copyToClipboard(
              'top-left',
              'primary',
              `<i class='fas fa-copy'></i>`
            )}}},[_c('i',{class:`fas fa-${
              _vm.copyPasswordText ? 'circle-check' : 'copy'
            } mr-1`}),_vm._v(" "+_vm._s(_vm.copyPasswordText ? "Copiada!" : "Copiar")+" ")]),_c('vs-button',{staticClass:"w-100",attrs:{"size":"large","flat":_vm.showPassword,"gradient":!_vm.showPassword,"danger":_vm.showPassword,"success":!_vm.showPassword},on:{"click":_vm.togglePasswordVisibility}},[_c('i',{class:`fas fa-${_vm.showPassword ? 'eye-slash' : 'eye'} mr-1`}),_vm._v(" "+_vm._s(_vm.showPassword ? "Ocultar" : "Ver")+" ")])],1)]),_c('div',{staticClass:"col-12 col-lg bg-dark p-2 rounded reporte",staticStyle:{"height":"500px"}},[_c('div',{staticClass:"vista-previa d-flex justify-content-center align-items-center rounded"},[_c('h5',[_vm._v("Cargando reporte...")]),_c('modalIframe',{attrs:{"url":_vm.selectedReport?.url ?? '',"likeElement":true}})],1)]),_c('div',{staticClass:"col-12 col-lg-4 content-list-reports"},[_c('div',{ref:"reportListContainer",staticClass:"row list-reports reportListContainer"},_vm._l((_vm.listaReportes),function(reporte,index){return _c('div',{key:index,staticClass:"col-12 d-flex align-items-center rounded card-report mt-2",on:{"click":function($event){return _vm.clickHandle(reporte, index)}}},[_c('div',{staticClass:"image-container w-100 rounded",style:({
              transition: 'box-shadow 0.3s ease',
              boxShadow:
                index === _vm.selectedReport?.index
                  ? '0 0 0 3px #186bdd'
                  : 'none',
              cursor: 'default',
            })},[_c('picture',[_c('div',{staticClass:"d-block text-center"},[_c('div',{staticClass:"d-flex justify-content-center mt-4"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center",staticStyle:{"max-height":"200px","height":"200px"}},[_c('vs-button',{staticClass:"position-absolute m-0",staticStyle:{"z-index":"10","top":"0","left":"10px","border-radius":"0 0 5px 5px"},attrs:{"relief":"","danger":"","size":"small"}},[_vm._v("Nivel "+_vm._s(reporte.nivel))]),_c('h5',[_vm._v("Vista previa...")]),_c('modalIframe',{staticClass:"iframe",attrs:{"url":reporte?.url ?? '',"likeElement":true}})],1)])])]),_c('div',{staticClass:"overlay rounded",style:({
                opacity: index === _vm.selectedReport?.index ? '1' : '0',
              })},[_c('article',{staticClass:"d-flex flex-column align-items-center",staticStyle:{"gap":"0.5rem"}},[_c('i',{staticClass:"fas fa-chart-simple text-white",staticStyle:{"font-size":"2rem"}}),_c('p',{staticClass:"custom-descripcion text-white-50"},[_vm._v(" "+_vm._s(reporte.descripcion)+" ")])])])])])}),0)])]):_c('div',{staticClass:"text-light bg-dark rounded",staticStyle:{"height":"600px","display":"grid","place-items":"center","font-size":"2rem"}},[_c('helexium-loader',{staticClass:"loader"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }