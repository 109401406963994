<template>
  <div class="p-4 position-relative">
    <!-- icon -->
    <div
      v-b-tooltip.hover
      title="Ampliar Grafico"
      class="position-absolute"
      style="top: 0 !important; right: 0 !important"
    >
      <vs-button flat icon success v-b-modal="`modal_chart${type}`">
        <i :class="`fas fa-${icon ?? 'chart-column'}`"></i>
      </vs-button>
    </div>
    <!-- text info -->
    <div class="d-flex flex-column">
      <h3 class="text-white-50 text-truncate">
        {{ title ?? "General info" }}
      </h3>
      <span class="font-weight-bold h4">{{ info ?? "6 h" }}</span>
    </div>
    <!-- apexchart -->
    <!-- competencias persona -->
    <div v-if="type === 1" class="competencias_persona" style="height: 75%">
      <div v-if="chartPersonaData.length > 0" id="chartPersona"></div>
      <!-- empty grafic -->
      <div
        v-if="!chartPersonaData.length"
        class="row align-items-center h-100 fade-in-out"
      >
        <div class="col-12 d-flex justify-content-center icon-ranking">
          <div
            class="custom-bg-icon position-absolute"
            style="
              border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
              width: 100px;
              height: 100px;
            "
          ></div>

          <img
            loading="lazy"
            class="position-absolute"
            style="width: 80px"
            src="img/barra-grafica.png"
            alt="icon_empty_grafic"
          />
        </div>
        <div
          class="col-12 text-center"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <h3>Resultados Evaluaciones Competencias</h3>
          <p class="text-white-50 h4">
            Grafico de resultados por competencias.
          </p>
        </div>
      </div>
    </div>

    <!-- departamentos -->
    <div
      v-if="type === 2"
      class="competencias_departamentos"
      style="height: 75%"
    >
      <div v-if="chartData.length > 0" id="chart"></div>
      <!-- empty grafic -->
      <div
        v-if="!chartData.length"
        class="row align-items-center h-100 fade-in-out"
      >
        <div class="col-12 d-flex justify-content-center icon-ranking">
          <div
            class="custom-bg-icon position-absolute"
            style="
              border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
              width: 100px;
              height: 100px;
            "
          ></div>

          <img
            loading="lazy"
            class="position-absolute"
            style="width: 80px"
            src="img/barra-grafica.png"
            alt="icon_empty_grafic"
          />
        </div>
        <div
          class="col-12 text-center"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <h3>Resultados Evaluaciones Departamentos</h3>
          <p class="text-white-50 h4">
            Grafico de resultados por departamentos.
          </p>
        </div>
      </div>
    </div>

    <!-- modalChart -->
    <b-modal
      :title="title"
      :id="`modal_chart${type}`"
      size="xl"
      centered
      @shown="onModalShown"
      hide-footer
    >
      <div style="min-height: 450px">
        <!-- apexchartModal -->
        <!-- by persona competencias -->
        <div v-if="type === 1" id="modalChartPersona"></div>
        <!-- by departamentos -->
        <div v-if="type === 2" id="modalChart"></div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ApexCharts from "apexcharts";

const colors = [
  "#26A0FC",
  "#26E7A6",
  "#FEBC3B",
  "#FF6178",
  "#8B75D7",
  "#69808A",
  "#46B3A9",
  "#D830EB",
];

var options = {
  series: [
    {
      name: "Promedio",
      data: [],
    },
  ],
  chart: {
    height: 300,
    type: "bar",
    events: {
      click: function (chart, w, e) {
        console.log(chart, w, e);
      },
    },
  },
  colors: colors,
  plotOptions: {
    bar: {
      columnWidth: "45%",
      distributed: true,
    },
  },
  tooltip: {
    x: {
      formatter: function (val) {
        return val;
      },
    },
  },
  dataLabels: {
    enabled: true,
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories: [
      // ["John", "Doe"],
      // ["Joe", "Smith"],
      // ["Jake", "Williams"],
      // "Amber",
      // ["Peter", "Brown"],
      // ["Mary", "Evans"],
      // ["David", "Wilson"],
      // ["Lily", "Roberts"],
    ],
    labels: {
      formatter: (value) => {
        return value.length > 15 ? value.substring(0, 15) + "..." : value;
      },

      style: {
        colors: colors,
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    max: 100,
  },
  responsive: [
    {
      breakpoint: 700,
      options: {
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        legend: {
          position: "right",
          verticalAlign: "top",
          containerMargin: {
            left: 35,
            right: 60,
          },
        },
      },
    },
  ],
};

export default {
  props: {
    title: String,
    info: String,
    icon: String,
    to: String,
    type: Number,
  },
  components: {},
  data() {
    return {
      usuario: {},
      chartPersonaData: [],
      chartData: [],
      chartPersona: null,
      chart: null,
      modalChartPersona: null,
      modalChart: null,
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));

      this.chartPersonaData = await this.getListEvaluacionesByPersona(
        this.usuario.id
      );
      this.chartData = await this.getListEvaluacionesByEmpresa(
        this.usuario.empresa.id
      );

      this.$nextTick(() => {
        this.setupChart(
          "chart",
          this.chart,
          this.chartData.slice(0, 5),
          options,
          2
        );
        this.setupChart(
          "chartPersona",
          this.chartPersona,
          this.chartPersonaData.slice(0, 5),
          options,
          1
        );
      });

      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
      console.error("Error al montar el componente: ", error);
    }
  },
  methods: {
    onModalShown() {
      if (!this.modalChart || !this.modalChartPersona) {
        if (this.type === 2) {
          this.setupChart(
            "modalChart",
            this.modalChart,
            this.chartData,
            options,
            2,
            400
          );
        } else {
          this.setupChart(
            "modalChartPersona",
            this.modalChartPersona,
            this.chartPersonaData,
            options,
            1,
            400
          );
        }
      }
    },
    // Función para configurar y renderizar el gráfico
    setupChart(idChart, chart, data, options, type, chartHeight) {
      const container = document.querySelector(`#${idChart}`);
      if (!container) {
        // console.error(`No se encontró el contenedor con id ${idChart}`);
        return;
      }

      const processedData = data.map((item) => item.promedioValoracion || 0);
      const categories = data.map((item) =>
        type === 2 ? item.departamento : item.competencia
      );

      const height = chartHeight || options.chart.height;
      chart = new ApexCharts(container, {
        ...options,
        chart: { ...options.chart, height: height },
      });

      chart.render();
      chart.updateSeries([{ data: processedData }]);
      chart.updateOptions({
        xaxis: {
          categories: categories,
        },
      });
    },
    async getListEvaluacionesByPersona(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "EstadisticasEvaluacionesEmpresaV2/PromedioEvaluacionesPersona/" +
            personaId,
        });
        // console.log("list PromedioEvaluacionesCompetenciasByPersona... ", res);
        if (res.success) {
          return res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getListEvaluacionesByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "EstadisticasEvaluacionesEmpresaV2/PromedioEvaluacionesDepartamentos/" +
            empresaId,
        });
        // console.log("list PromedioEvaluacionesDepartamentos... ", res);
        if (res.success) {
          return res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
  },
  filters: {},
};
</script>
<style scoped>
.custom-bg-icon {
  background: radial-gradient(circle, #ff94a7, #d70027);
  box-shadow: 0px -1px 33px -8px #d70027;
  -webkit-box-shadow: 0px -1px 33px -8px #d70027;
  -moz-box-shadow: 0px -1px 33px -8px #d70027;
}
.icon-ranking {
  animation: leftAndRight 2s ease-in-out infinite;
}
.competencias_persona,
.competencias_departamentos {
  min-height: 300px;
}

@keyframes leftAndRight {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
</style>
