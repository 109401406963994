<template>
  <div class="progress_wrapper">
    <div
      :class="progressColor"
      :style="{ width: circleSize, height: circleSize }"
    >
      <div
        class="progress_inner"
        :style="{ width: circleSize, height: circleSize }"
      >
        <div class="inner" :style="innerStyle">
          <div
            class="percent"
            :style="{
              color: waterColor,
              fontSize: textSize,
              lineHeight: circleSize,
            }"
          >
            {{ percent }}
            <small>%</small>
          </div>
          <div class="water" :style="waterStyle"></div>
          <div class="glare"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    size: {
      type: Number,
      default: 250,
    },
  },
  computed: {
    circleSize() {
      return `${this.size}px`;
    },
    borderSize() {
      return `${Math.max(this.size / 50, 3)}px`;
    },
    waterLevel() {
      return `${100 - this.percent}%`;
    },
    waterColor() {
      const val = this.percent;
      const colorInc = 100 / 3;
      if (val < colorInc) return "#f21343"; // Rojo
      if (val < colorInc * 2) return "#ff8200"; // Naranja
      return "#1A5CFF"; // Azul
    },
    progressColor() {
      return {
        borderColor: this.waterColor,
      };
    },
    waterStyle() {
      return {
        top: this.waterLevel,
        backgroundColor: this.waterColor,
        borderColor: this.waterColor,
        width: "200%",
        height: "200%",
        left: "-50%",
        borderRadius: "40%",
      };
    },
    innerStyle() {
      return {
        borderColor: this.waterColor,
        borderWidth: this.borderSize,
        width: "90%",
        height: "90%",
        top: "5%",
        left: "5%",
      };
    },
    textSize() {
      return `${Math.max(this.size / 5, 20)}px`;
    },
  },
};
</script>

<style scoped>
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.progress_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.progress_inner {
  position: relative;
  border-radius: 50%;
}

.inner {
  position: absolute;
  overflow: hidden;
  z-index: 2;
  border-radius: 50%;
  border-style: solid;
  transition: border-color 1s ease, border-width 1s ease;
}

.water {
  position: absolute;
  z-index: 1;
  animation: spin 10s linear infinite;
  transition: all 1s ease;
  opacity: 0.6;
}

.glare {
  position: absolute;
  top: -120%;
  left: -120%;
  z-index: 5;
  width: 200%;
  height: 200%;
  transform: rotate(45deg);
  border-radius: 50%;
  transition: all 1s ease;
}

.percent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-align: center;
  transition: color 1s ease;
}
</style>
