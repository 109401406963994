<template>
  <section class="pb-3">
    <SidebarStyle9 />
    <HeaderStyle9 :visibleItems="false" />
    <!-- content-page -->
    <div class="content-page px-4" style="">
      <!-- row 1 -->
      <div class="row mt-4" style="gap: 0.5rem">
        <div class="col-12 col-lg-8 p-0">
          <!-- info profile -->
          <cardInfoProfile />
          <div
            class="row"
            style="width: 100% !important; margin: 0 auto; gap: 0.5rem"
          >
            <div
              class="position-relative col-12 col-lg bg-dark rounded p-0"
              data-aos="fade-up-right"
              data-aos-duration="1000"
            >
              <!-- control buttons -->
              <!-- next -->
              <vs-button
                circle
                icon
                flat
                danger
                class="position-absolute next_button"
                @click="changeSlide(1, 2)"
              >
                <i class="fas fa-chevron-circle-right"></i>
              </vs-button>
              <!-- back -->
              <vs-button
                circle
                icon
                flat
                danger
                class="position-absolute back_button"
                @click="changeSlide(-1, 2)"
              >
                <i class="fas fa-chevron-circle-left"></i>
              </vs-button>
              <div class="grafic_carousel h-100" ref="grafic_carousel">
                <!-- grafics -->
                <cardChartInfo
                  title="Evaluaciones Departamentos"
                  info="Promedios"
                  :type="2"
                />
                <cardChartInfo
                  title="Mis Evaluaciones Competencias"
                  info="Promedios"
                  :type="1"
                />
              </div>
            </div>
            <div class="col-12 col-lg-4 p-0">
              <cardProgressInfo
                title="Mis Actividades"
                to="/helex/perfil/MisActividades"
                toTitle="Ir a Mis Actividades"
                type="Actividades"
              />
            </div>
          </div>
        </div>
        <!-- medallas y reportes -->
        <div class="col-12 col-lg p-0 position-relative">
          <!-- control buttons -->
          <!-- next -->
          <vs-button
            circle
            icon
            flat
            danger
            class="position-absolute next_button"
            @click="changeSlide(1, 1)"
          >
            <i class="fas fa-chevron-circle-right"></i>
          </vs-button>
          <!-- back -->
          <vs-button
            circle
            icon
            flat
            danger
            class="position-absolute back_button"
            @click="changeSlide(-1, 1)"
          >
            <i class="fas fa-chevron-circle-left"></i>
          </vs-button>
          <!-- grafic ranking -->
          <div class="grafic_ranking h-100" ref="grafic_ranking">
            <!-- ranking -->
            <!-- by personas area -->
            <cardRanking :type="1" />
            <!-- by departamentos -->
            <cardRanking :type="2" />
          </div>
        </div>
      </div>
      <!-- row 2 -->
      <div class="row mt-2" style="gap: 0.5rem">
        <div class="col-12 col-lg-8 p-0">
          <div
            class="row"
            style="width: 100% !important; margin: 0 auto; gap: 0.5rem"
          >
            <div class="col-12 col-lg p-0">
              <cardProgressInfo
                title="Mis Capacitaciones"
                to="/helex/Capacitaciones/MisCapacitaciones"
                toTitle="Ir a Mis Capacitaciones"
                type="Capacitaciones"
              />
            </div>
            <div class="col-12 col-lg p-0">
              <cardProgressInfo
                title="Mis Evaluaciones"
                to="/helex/pendientes"
                toTitle="Ir a Mis Evaluaciones"
                type="Evaluaciones"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg p-0 d-flex flex-column" style="gap: 0.5rem">
          <!-- lista de medallas -->
          <cardMedallas />
          <!-- card powerBIreport -->
          <cardReportes />
        </div>
      </div>
    </div>
    <!-- modal funciones -->
    <b-modal size="xl" id="modalFunciones" hide-footer centered>
      <template #modal-title> AUTORIZACION PARA DESEMPEÑAR FUNCIONES </template>
      <div class="d-block text-center">
        <AutorizacionFunciones
          :dataCicloEvaluacion="dataCicloEvaluacion"
          :autorizacion="autorizacion"
        />
      </div>
    </b-modal>
  </section>
</template>
<script>
import AutorizacionFunciones from "../SuperAdmin/ModalFunciones.vue";
import HeaderStyle9 from "@/components/partials/backend/HeaderStyle/HeaderStyle9";
import SidebarStyle9 from "@/components/partials/backend/SidebarStyle/SidebarStyle9";

import cardReportes from "./cardReportes.vue";
import cardMedallas from "./cardMedallas.vue";
import cardInfoProfile from "./cardInfoProfile.vue";
import cardProgressInfo from "./cardProgressInfo.vue";
import cardChartInfo from "./cardChartInfo.vue";
import cardRanking from "./cardRanking.vue";

export default {
  components: {
    AutorizacionFunciones,
    HeaderStyle9,
    cardReportes,
    cardMedallas,
    cardInfoProfile,
    cardProgressInfo,
    cardChartInfo,
    cardRanking,
    SidebarStyle9,
  },
  data() {
    return {
      userLogged: null,
      dataCicloEvaluacion: {},
      autorizacion: {},
    };
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    async initializeData() {
      this.userLogged = JSON.parse(localStorage.getItem("setPersonaAct"));
      this.autorizacion = await this.getEmpresaAutorizacion();
      if (this.autorizacion && this.autorizacion.length > 0) {
        await this.getPromedioCicloPersona(this.userLogged.id);
      }
    },
    changeSlide(delta, type) {
      const carousel =
        type === 1 ? this.$refs.grafic_ranking : this.$refs.grafic_carousel;
      const width = carousel.offsetWidth;
      const scrollLeft = carousel.scrollLeft;
      carousel.scrollTo(scrollLeft + width * delta, 0);
    },
    async getEmpresaAutorizacion() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "EmpresaAutorizacionFuncion/GetEmpresaAutorizacion/" +
            this.userLogged.empresa.id,
        });
        // console.log("autorización .....", res);
        return res;
      } catch (error) {
        console.log("err autorización", error);
      }
    },
    async getPromedioCicloPersona(usuarioId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Persona/GetPromedioCicloPersona/" + usuarioId,
        });
        console.log("list GetPromedioCicloPersona... ", res);
        if (res.length > 0) {
          //let data = res.filter((x) => x.terminadas == x.total && x.aceptado == 0 && x.promedio > 64);
          //abr 16-07-2024 cambio de validación
          //verificar si tiene autoevaluaciones pendientes
          let Pendientes = res.filter((x) => x.terminadas == 0);
          if (Pendientes.length > 0) {
            console.log("no mostrar porque tiene ev. pendientes");
          } else {
            //verificar si algun registro tiene campo aceptado = 1
            let aceptado = res.filter((x) => x.aceptado > 0);
            if (aceptado.length == 0) {
              let data2 = res.filter((x) => x.aceptado == 0 && x.promedio > 64);
              console.log("resultado evaluaciones", data2);
              if (data2.length > 0) {
                //aqui verificar si ya está almacenada la aceptación de funciones
                // this.cicloEvaluacionId = data[0].cicloEvaluacionId;
                this.dataCicloEvaluacion = data2[0];
                this.$bvModal.show("modalFunciones");
                // alert('tiene evaluación finalizada')-
              }
            }
          }
        }
      } catch (error) {
        // console.log("err", error);
      }
    },
    modalView() {
      //console.log(this.$store.state.modalShow);
      if (this.$store.state.modalShow) {
        this.$store.commit("modalShowRefresh", false);
        //this.modalShow = false
      } else {
        this.$store.commit("modalShowRefresh", true);
      }
    },
  },
};
</script>
<style scoped>
.content-page {
  padding: 60px 15px 0px;
}

/* grafic carousel */
.grafic_carousel,
.grafic_ranking {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.grafic_carousel > *,
.grafic_ranking > * {
  flex: 1 0 100%;
  scroll-snap-align: center;
}

/* control buttons */

.next_button {
  position: fixed;
  right: 10px;
  top: 50%;
  transform: translate(50%, -50%);
}

.back_button {
  left: -20px;
  top: 50%;
  transform: translate(50%, -50%);
}
</style>
