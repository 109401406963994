<script>
import RenderAvatar from './RenderAvatar.vue'
import RenderImage from './RenderImage.vue'

export default {
  components: {
    RenderAvatar,
    RenderImage
  },
  data() {
    return {
      result: null,
      stringAvatar: null
    }
  },
  computed: {
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    }
  },
  async mounted() {
    const response = await this.$store.getters.fetchGet({ path: `Persona/GetPersonaById/${this.userLoggedIn.id}` })
    const result = await response.json()
    if (result.avatar) {
      this.result = result.avatar
      this.stringAvatar = result.stringAvatar
    }
  }
}
</script>

<template>
  <RenderAvatar v-if="result" style="width: 100%" :inheritedStringAvatar="stringAvatar" />
  <RenderImage v-else />
</template>
